.App {
  text-align: center;
}

#root {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 400px;
  text-align: center;
  font-family: 'Nunito sans';
}

.coupounContent {
  text-align: center;
  width: 800px;
}

.instructions{
  text-align: left;
  background-color: #1a1713;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 15px;
}
.instructions ol {
  padding: 10px 20px;
}
.instructions li {
  padding: 5px 0px;
}

.error{
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
}

.success{
  color: green;
  font-weight: bold;
  margin-bottom: 20px;
}

.company {
  margin-top: 20px;
}
.company, .discord, .loading{
  margin-bottom: 30px;
}
.discord a {
  font-size: 18px;
}
.logo img {
  width: 250px;
  height: 250px;
}
.amount{
  background-color: #1a1713;
  color: white;
  padding: 20px;
  text-align: left;
}

.wallet{
  background-color: #1a1713;
  color: white;
  padding: 10%;
  border-radius: 15px;
  margin-top: 45px;
}

.couponAddress{
  margin-top: 20px;
}

.nftInformation{
  display: flex;
  padding:5px;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 20px;
}
/*
.nftInformation div{
  margin:5px;
  width: 300px;
  height: 80px;
}*/

.flexcolumn{
  flex-direction: column;
}

.infoText{
  font-weight: bold;
  text-align: left;
}

#eth{
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.statusBox{
  align-items: center;
  justify-content: center;
  display: flex;
}

.status{  
  font-weight: bold;
  background-color: #384457;
  padding: 15px;
  border-radius: 10px;
}

.input{
  margin-top: 20px;
  margin-bottom:  20px;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.button{
  background-image: linear-gradient(#614AE2, #803DED);
  color: white;
  width: 100%;
  padding: 5%;
  border-radius: 10px;
  font-weight: bold;
  border-color: #5b339b;
  transition: transform 0.3s linear;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;  
}

.button:hover{
  -webkit-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  -moz-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  -o-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  -ms-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  cursor: pointer;
}

.navigateButton{
  background-image: linear-gradient(#614AE2, #803DED);
  color: white;
  width: 100%;
  padding: 5%;
  border-radius: 10px;
  font-weight: bold;
  border-color: #5b339b;
  transition: transform 0.3s linear;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;  
  margin-top: 20px;
  margin-bottom: 20px;
}

.navigateButton:hover{
  -webkit-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  -moz-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  -o-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  -ms-transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  transform: scale(1.1) translate3d( 0, 0, 0) perspective(1px);
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
